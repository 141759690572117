exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-privacy-and-cookies-policy-en-js": () => import("./../../../src/pages/privacy-and-cookies-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-and-cookies-policy-en-js" */),
  "component---src-pages-privacy-and-cookies-policy-js": () => import("./../../../src/pages/privacy-and-cookies-policy.js" /* webpackChunkName: "component---src-pages-privacy-and-cookies-policy-js" */),
  "component---src-pages-privacy-and-cookies-policy-pl-js": () => import("./../../../src/pages/privacy-and-cookies-policy.pl.js" /* webpackChunkName: "component---src-pages-privacy-and-cookies-policy-pl-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-articles-page-js": () => import("./../../../src/templates/articles-page.js" /* webpackChunkName: "component---src-templates-articles-page-js" */),
  "component---src-templates-careers-page-js": () => import("./../../../src/templates/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-contact-us-page-js": () => import("./../../../src/templates/contact-us-page.js" /* webpackChunkName: "component---src-templates-contact-us-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-what-we-do-js": () => import("./../../../src/templates/what-we-do.js" /* webpackChunkName: "component---src-templates-what-we-do-js" */)
}

